<div class="input-label">
  @if (label()) {
    <label [for]="'input_' + id()"
      >{{ label() }}
      @if (required()) {
        <sup class="required">*</sup>
      }
    </label>
  }
  <div
    class="input-wrapper"
    [class.error]="error()"
    [class.disabled]="disabled()"
  >
    <input
      [id]="'input_' + id()"
      [name]="name()"
      [type]="type()"
      [inputMode]="inputMode()"
      [placeholder]="placeholder()"
      [disabled]="disabled()"
      [showMaskTyped]="maskTyped()"
      [mask]="inputMask()"
      [(ngModel)]="value"
      [ngModelOptions]="{ standalone: true }"
      (input)="onChange()"
      (change)="onChange()"
      (blur)="onTouchedFn()"
      class="input-custom"
    />
    <!--    [class.empty]="value === ''"-->
  </div>
</div>
